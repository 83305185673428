import React, { FunctionComponent, SyntheticEvent, useState, useEffect } from 'react';
import I18n from 'common/i18n';
import { ForgeToast, ForgeCircularProgress, ForgeTextField, ForgeButton } from '@tylertech/forge-react';
import { fetchJsonWithDefaultHeaders } from 'common/http';
import RapidDeployInstructions from './RapidDeployInstructions';

/**
 * Manage the Rapid Deploy page in the admin panel
 */
const RapidDeployManager: FunctionComponent = () => {
  // This tracks the URL entered in the text box
  const [sourceDomain, setSourceDomain] = useState('');

  // This tracks the spinner which is on during rapid deploy
  const [spinnerOn, setSpinnerOn] = useState(false);

  // This tracks whether the get assets button is enabled or disabled
  // If the user has entered any text, then the button is enabled
  const [disableGetAssetsBtn, setDisableGetAssetsBtn] = useState(true);

  const rerenderGetAssetsButton = () => {
    if (spinnerOn) {
      setDisableGetAssetsBtn(true);
    } else {
      setDisableGetAssetsBtn(sourceDomain.length > 0 ? false : true);
    }
  };

  // The get assets button is enabled/disabled as a side effect of the user typing into the text box
  // or during a rapid deploy when the spinner is on.
  useEffect(() => {
    rerenderGetAssetsButton();
  }, [sourceDomain, spinnerOn]);

  // This tracks the toast, whether it is showing and its message
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const toastOptions = {
    placement: 'top',
    message: toastMessage,
    duration: 5000,
    id: 'rapid-deploy-toast'
  };

  const clearToast = () => {
    setShowToast(false);
  };

  // Function to update the state when the user is typing into the text box
  const onSourceDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredSourceDomain = e.target.value.trim();
    if (enteredSourceDomain != sourceDomain) {
      setSourceDomain(enteredSourceDomain);
    }
  };

  const onFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    let success = true;
    setSpinnerOn(true);

    try {
      // Do the rapid deploy
      const response = await fetchJsonWithDefaultHeaders(
        `/api/rapid_deploy/${sourceDomain}?method=pullPublicStories`,
        { method: 'POST' }
      );
      console.log(response.length, 'Successfully deployed assets');
    } catch (err) {
      success = false;
      console.error(err);
    }

    setSpinnerOn(false);
    setToastMessage(
      success
        ? I18n.t('shared.rapid_deploy.successful_deploy')
        : I18n.t('shared.rapid_deploy.unsuccessful_deploy')
    );
    setShowToast(true);
    if (success) {
      setSourceDomain('');
    }
  };

  return (
    <div>
      <p className="instructions-label">{I18n.t('shared.rapid_deploy.instructions')}</p>
      <form className="rapid-deploy-container" onSubmit={onFormSubmit}>
        <div className="source-domain-container">
          <ForgeTextField float-label-type="always">
            <input
              type="text"
              id="input-always"
              placeholder={I18n.t('shared.rapid_deploy.text_field_placeholder')}
              onChange={onSourceDomainChange}
              value={sourceDomain}
            />
            <label htmlFor="input-always" slot="label">
              {I18n.t('shared.rapid_deploy.text_field_label')}
            </label>
          </ForgeTextField>
        </div>
        <div className="get-assets-container">
          <ForgeButton type="unelevated" style={{ width: '100%' }}>
            <button style={{ width: '100%' }} disabled={disableGetAssetsBtn} type="submit">
              {I18n.t('shared.rapid_deploy.get_assets')}

              {spinnerOn && (
                <span>
                  {' '}
                  <ForgeCircularProgress className="custom-circular-progress"></ForgeCircularProgress>{' '}
                </span>
              )}
            </button>
          </ForgeButton>
        </div>
        <ForgeToast options={toastOptions} open={showToast} onDismiss={clearToast}></ForgeToast>
      </form>
      <RapidDeployInstructions />
    </div>
  );
};

export default RapidDeployManager;
