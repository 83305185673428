import React, { FunctionComponent } from 'react';
import { ForgeBadge } from '@tylertech/forge-react';
import I18n from 'common/i18n';

/**
 * Rapid Deploy Instructions
 */
const RapidDeployInstructions: FunctionComponent = () => {
  return (
    <div className="long-instructions-box">
      <div className="long-instructions-title">{I18n.t('shared.rapid_deploy.how_to')}</div>

      <span className="step-name">{I18n.t('shared.rapid_deploy.steps.first.name')}</span>
      <span className="step-title">{I18n.t('shared.rapid_deploy.steps.first.title')}</span>

      <div className="you-are-here-box">
        <ForgeBadge>{I18n.t('shared.rapid_deploy.badge')}</ForgeBadge>
        <div className="step-2-name">
          {I18n.t('shared.rapid_deploy.steps.second.name')}
          <span className="step-2-title">{I18n.t('shared.rapid_deploy.steps.second.title')}</span>
        </div>
        <div className="step-2-text">{I18n.t('shared.rapid_deploy.steps.second.text')}</div>
        <div className="step-2-name">{I18n.t('shared.rapid_deploy.steps.second.note')}</div>
        <div className="step-2-text">{I18n.t('shared.rapid_deploy.steps.second.note_text')}</div>
      </div>

      <span className="step-name">{I18n.t('shared.rapid_deploy.steps.third.name')}</span>
      <span className="step-title">{I18n.t('shared.rapid_deploy.steps.third.title')}</span>
      <div className="step-text">{I18n.t('shared.rapid_deploy.steps.third.text')}</div>

      <span
        className="option-text"
        dangerouslySetInnerHTML={{ __html: I18n.t('shared.rapid_deploy.steps.third.option_1.title') }}
      />
      <ul className="option-items">
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_1.list.first')}</li>
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_1.list.second')}</li>
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_1.list.third')}</li>
        <li
          dangerouslySetInnerHTML={{ __html: I18n.t('shared.rapid_deploy.steps.third.option_1.list.fourth') }}
        />
      </ul>

      <div className="option-text">{I18n.t('shared.rapid_deploy.steps.third.option_2.title')}</div>
      <ul className="option-items">
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_2.list.first')}</li>
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_2.list.second')}</li>
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_2.list.third')}</li>
        <li>{I18n.t('shared.rapid_deploy.steps.third.option_2.list.fourth')}</li>
        <li
          dangerouslySetInnerHTML={{ __html: I18n.t('shared.rapid_deploy.steps.third.option_2.list.fifth') }}
        />
      </ul>

      <span className="step-name">{I18n.t('shared.rapid_deploy.steps.fourth.name')}</span>
      <span className="step-title">{I18n.t('shared.rapid_deploy.steps.fourth.title')}</span>
      <div className="step-text">{I18n.t('shared.rapid_deploy.steps.fourth.text')}</div>

      <p>
        <span className="step-name">{I18n.t('shared.rapid_deploy.steps.fifth.name')}</span>
        <span className="step-title">{I18n.t('shared.rapid_deploy.steps.fifth.title')}</span>
        <div className="step-text">{I18n.t('shared.rapid_deploy.steps.fifth.text')}</div>
      </p>

      <p
        className="step-text"
        dangerouslySetInnerHTML={{ __html: I18n.t('shared.rapid_deploy.more_info') }}
      />
    </div>
  );
};

export default RapidDeployInstructions;
